export const splitByNewLine = (string) => {
  if(typeof string !== "string") {
    return [];
  }
  return string.split("\n").filter(str => str !== "");
};

export const capitalize = (string) => {
  if(!string) {
    return;
  }
  const capitalLetter = string[0].toUpperCase();
  return capitalLetter + string.substring(1);
};

export const pluralize = (count, singular, plural) => {
  if(count === 1) {
    return singular;
  } else {
    return plural;
  }
};
