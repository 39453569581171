import Link from "next/link";
import useSWR from "swr";
import {
  NoShow,
  TaskDone,
  TaskNotDone,
} from "components/Icons";
import fetcher from "utils/fetcher";
import styles from "./index.module.css";

const TaskNoShow = ({
  isViewOnly
}) => {
  const { data, error, isLoading } = useSWR(
    {
      url: "/api/no-show",
      params: {},
    },
    fetcher
  );

  if(isLoading || error) {
    return null;
  }

  const hasPublishedList = !!data.length;

  if(isViewOnly) {
    if(hasPublishedList) {
      return (
        <Link href="/lists/no-show">
          <div className={styles.tile}>
            <div className={styles.tileIcon}>
              <NoShow />
            </div>
            <div className={styles.tileLabel}>View no-shows</div>
          </div>
        </Link>
      );
    } else {
      return null;
    }
  }

  return (
    <Link href="/tasks/no-show">
      <div className={styles.tile}>
        <div className={styles.tileIcon}>
          {hasPublishedList ? <TaskDone /> : <NoShow />}
        </div>
        <div className={styles.tileLabel}>
          <h3 className={styles.taskTitle}>Review no-shows</h3>
          <p className={styles.taskStatus}>
            {hasPublishedList
              ? "Published to vendors"
              : "Not published to vendors"
            }
          </p>
        </div>
      </div>
    </Link>
  );
};

export default TaskNoShow;