import Link from "next/link";
import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import luxonPlugin from "@fullcalendar/luxon3";
import { Interval } from "luxon";
import useFetcher from "hooks/fetcher";
import { TIMEZONE } from 'lib/consts';
import { getISODate, getLuxonDate, getNowISODate } from "utils/date";
import { pluralize } from 'utils/string';
import styles from "./index.module.css";

const TaskCalendar = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const hasDateRange = !!(startDate && endDate);

  const todayIsoDate = getNowISODate();

  const { data: noShowLists, isLoading: isLoadingNoShowLists } = useFetcher(hasDateRange ? {
    url: "/api/no-show",
    params: {
      date_start: startDate.toISODate(),
      date_end: endDate.toISODate(),
    },
  } : null);

  const { data: appointments, isLoading: isLoadingAppointments } = useFetcher(hasDateRange ? {
    url: "/api/appointments",
    params: {
      search_date_start: startDate.toJSDate(),
      search_date_end: endDate.toJSDate(),
    },
  } : null);

  const { data: reports, isLoading: isLoadingReports } = useFetcher(hasDateRange ? {
    url: "/api/reports",
    params: {
      search_date_start: startDate.toJSDate(),
      search_date_end: endDate.toJSDate(),
    },
  } : null);

  const isLoading = isLoadingNoShowLists || isLoadingAppointments || isLoadingReports;

  let events;
  if(hasDateRange) {
    const interval = Interval.fromDateTimes(startDate, endDate);
    events = interval.splitBy({ days: 1 }).map((d) => {
      return {
        start: d.start.toJSDate()
      };
    });
  }

  const handleDatesSet = (datesInfo) => {
    const { start, end } = datesInfo;
    const luxonStart = getLuxonDate(start);
    const luxonEnd = getLuxonDate(end);
    setStartDate(luxonStart);
    setEndDate(luxonEnd);
  };

  const renderEvent = (eventInfo) => {
    if(isLoading) {
      return null;
    }

    const eventIsoDate = getISODate(eventInfo.event.start);

    const noShowList = noShowLists?.find(l => {
      const listIsoDate = getISODate(l.date);
      return listIsoDate === eventIsoDate;
    });
    const hasNoShowList = !!noShowList;

    const canApproveNoShowList = !hasNoShowList && !(eventIsoDate > todayIsoDate);
    const canViewNoShowList = hasNoShowList;

    const appointmentsForDate = appointments?.filter((a) => {
      const appointmentIsoDate = getISODate(a.date);
      return appointmentIsoDate === eventIsoDate;
    });
    const hasAppointments = !!appointmentsForDate?.length;

    const reportsForDate = reports?.filter((item) => {
      const reportIsoDate = getISODate(item.exam?.appointment?.date);
      return reportIsoDate === eventIsoDate;
    });
    const hasReports = !!reportsForDate?.length;

    return (
      <div className={styles.event}>
        <ul className={styles.eventList}>
          {canViewNoShowList &&
            <li>
              <Link
                className={styles.eventAction}
                href={`/lists/no-show?date=${eventIsoDate}`}
              >
                View No-show List
              </Link>
            </li>
          }
          {canApproveNoShowList &&
            <li>
              <Link
                className={styles.eventAction}
                href={`/tasks/no-show?date=${eventIsoDate}`}
              >
                Approve No-show List
              </Link>
            </li>
          }
          {hasAppointments &&
            <li>
              <Link
                className={styles.eventAction}
                href={`/appointments?date=${eventIsoDate}`}
              >
                {appointmentsForDate.length} {pluralize(appointmentsForDate.length, "Claimant", "Claimants")}
              </Link>
            </li>
          }
          {hasReports &&
            <li>
              <Link
                className={styles.eventAction}
                href={`/reports?date=${eventIsoDate}`}
              >
                {reportsForDate.length} {pluralize(reportsForDate.length, "Report", "Reports")}
              </Link>
            </li>
          }
        </ul>
      </div>
    );
  };

  return (
    <div className={"noEventHover smallHeading"}>
      <FullCalendar
        datesSet={handleDatesSet}
        events={events}
        eventContent={renderEvent}
        height="auto"
        plugins={[ dayGridPlugin, interactionPlugin, luxonPlugin ]}
        initialView="dayGridMonth"
        timeZone={TIMEZONE}
        weekends={false}
      />
    </div>
  );
};

export default TaskCalendar;
