import Link from "next/link";
import { useUser } from "contexts/user";
import useFetcher from "hooks/fetcher";
import {
  EXAM_STATUS,
  CATEGORY_TYPES,
} from "lib/consts";
import { getExamStatus } from "lib/status";
import {
  getBoundsForToday,
} from "utils/date";
import appStyles from "styles/app.module.css";
import styles from "./index.module.css";

const PipelineDoctor = () => {
  const { role, user } = useUser();
  const { midnightToday, midnightTomorrow } = getBoundsForToday();

  const { data: exams, error, isLoading } = useFetcher({
    url: "/api/exams",
    params: {
      search_date_start: midnightToday,
      search_date_end: midnightTomorrow,
    },
  });

  if(!role || !user) {
    return null;
  }

  const statusMap = {
    [EXAM_STATUS.SCHEDULED.text]: 0,
    [EXAM_STATUS.CHECKING_IN.text]: 0,
    [EXAM_STATUS.READY_TO_START.text]: 0,
    [EXAM_STATUS.IN_PROGRESS.text]: 0,
    [EXAM_STATUS.COMPLETE.text]: 0,
  };

  const total = exams?.length || 0;

  if(exams?.length) {
    exams.forEach((item) => {
      const status = getExamStatus(item);
      if(statusMap.hasOwnProperty(status)) {
        statusMap[status]++;
      }
    });
  }

  const stages = [{
    label: "Not checked in yet",
    link: `/exams?status=${EXAM_STATUS.SCHEDULED.key}`,
    value: statusMap[EXAM_STATUS.SCHEDULED.text],
  }, {
    label: "Checking in",
    link: `/exams?status=${EXAM_STATUS.CHECKING_IN.key}`,
    value: statusMap[EXAM_STATUS.CHECKING_IN.text],
  }, {
    label: "Ready to be seen",
    link: `/exams?status=${EXAM_STATUS.READY_TO_START.key}`,
    value: statusMap[EXAM_STATUS.READY_TO_START.text],
  }, {
    label: "In progress",
    link: `/exams?status=${EXAM_STATUS.IN_PROGRESS.key}`,
    value: statusMap[EXAM_STATUS.IN_PROGRESS.text],
  }, {
    label: "Completed",
    link: `/exams?status=${EXAM_STATUS.COMPLETE.key}`,
    value: statusMap[EXAM_STATUS.COMPLETE.text],
  }, {
    label: "Total",
    link: "/exams",
    value: total,
  },
  ];

  return (
    <div className={styles.root}>
      <h2 className={appStyles.heading2}>{`Today's exams`}</h2>
      <div className={styles.stages}>
        {stages.map((stage, index) => (
          <div
            className={styles.stage}
            key={index}
          >
            <Link href={stage.link}>
              <div className={styles.stageLabel}>{stage.label}</div>
              <div className={styles.stageValue}>{stage.value}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PipelineDoctor;
