import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import Link from "next/link";
import Button from "components/Button";
import {
  FindPerson,
  Schedule,
} from "components/Icons";
import Layout from "components/Layout";
import Pipeline from "components/Pipeline";
import PipelinePendingExams from "components/Pipeline/PendingExams";
import PipelineReports from "components/Pipeline/Reports";
import RoleCheck from "components/RoleCheck";
import StaffOnly from "components/RoleCheck/StaffOnly";
import TaskCalendar from "components/TaskCalendar";
import TaskNoShow from "components/Task/NoShow";
import { ROLES } from "lib/consts";
import {
  getDateAsString,
} from "utils/date";
import appStyles from "styles/app.module.css";
import styles from "./index.module.css";

const PageHome = () => {
  const todayString = getDateAsString(new Date(), { includeWeekday: true, useLongForm: true });

  return (
    <Layout>
      <div className={styles.root}>
        <div className={styles.content}>
          <h1 className={appStyles.heading1}>{todayString}</h1>
          <div className={styles.pipeline}>
            <Pipeline />
          </div>
          <RoleCheck roles={[ROLES.RECEPTIONIST]}>
            <div className={styles.pipeline}>
              <PipelinePendingExams />
            </div>
          </RoleCheck>
          <RoleCheck roles={[ROLES.DOCTOR, ROLES.PROOFREADER]}>
            <div className={styles.pipeline}>
              <PipelineReports />
            </div>
          </RoleCheck>
          <StaffOnly>
            <div className={styles.section}>
              <h2 className={appStyles.heading2}>Daily Tasks</h2>
              <div className={styles.tiles}>
                <Link href="/register">
                  <div className={styles.tile}>
                    <div className={styles.tileIcon}>
                      <Schedule />
                    </div>
                    <div className={styles.tileLabel}>
                      Schedule an appointment
                    </div>
                  </div>
                </Link>
                <Link href="/incidents">
                  <div className={styles.tile}>
                    <div className={styles.tileIcon}>
                      <FindPerson />
                    </div>
                    <div className={styles.tileLabel}>
                      Claimant data retrieval
                    </div>
                  </div>
                </Link>
                <TaskNoShow />
              </div>
            </div>
          </StaffOnly>
          <RoleCheck roles={[ROLES.VENDOR]}>
            <div className={styles.section}>
              <h2 className={appStyles.heading2}>How can we help you?</h2>
              <div className={styles.tiles}>
                <Link href="/schedule">
                  <div className={styles.tile}>
                    <div className={styles.tileIcon}>
                      <Schedule />
                    </div>
                    <div className={styles.tileLabel}>
                      Schedule an appointment
                    </div>
                  </div>
                </Link>
                <Link href="/incidents">
                  <div className={styles.tile}>
                    <div className={styles.tileIcon}>
                      <FindPerson />
                    </div>
                    <div className={styles.tileLabel}>
                      Claimant data retrieval
                    </div>
                  </div>
                </Link>
                <TaskNoShow isViewOnly />
              </div>
            </div>
          </RoleCheck>
          <div className={styles.section}>
            <TaskCalendar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withPageAuthRequired(PageHome);
