import {
  APPOINTMENT_STATUS,
  EXAM_STATUS,
  QUESTIONNAIRE_STATUS,
  REPORT_STATUS,
} from "./consts";

export const getHasCheckedIn = appointment => !!appointment?.questionnaire;
export const getHasQuestionnaireBeenSubmitted = appointment => !!appointment?.questionnaire?.formResponse;
export const getHasQuestionnaireBeenApproved = appointment => !!appointment?.questionnaire?.isApproved;
export const getIsNoShow = appointment => !!appointment?.noShow;

export const getAppointmentStatus = (appointment) => {
  const isNoShow = getIsNoShow(appointment);
  const hasCheckedIn = getHasCheckedIn(appointment);
  const hasQuestionnaireBeenSubmitted = getHasQuestionnaireBeenSubmitted(appointment);
  const hasQuestionnaireBeenApproved = getHasQuestionnaireBeenApproved(appointment);

  if(isNoShow) {
    return APPOINTMENT_STATUS.NO_SHOW.text;
  } else {
    if(!hasCheckedIn) {
      return APPOINTMENT_STATUS.NOT_STARTED.text;
    }
    if(hasCheckedIn && !hasQuestionnaireBeenSubmitted) {
      return APPOINTMENT_STATUS.CHECKED_IN.text;
    }
    if(hasQuestionnaireBeenSubmitted && !hasQuestionnaireBeenApproved) {
      return APPOINTMENT_STATUS.QUESTIONNAIRE_SUBMITTED.text;
    }
    if(hasQuestionnaireBeenApproved) {
      return APPOINTMENT_STATUS.QUESTIONNAIRE_APPROVED.text;
    }
  }
};

export const getHasExamData = exam => !!exam?.formResponse;
export const getIsExamConfirmed = exam => !!exam?.confirmation;
export const getIsExamCheckedIn = (exam) => getHasCheckedIn(exam?.appointment);
export const getIsExamQuestionnaireApproved = (exam) => getHasQuestionnaireBeenApproved(exam?.appointment);
export const getIsNotExamined = exam => !!exam?.notExamined;

export const getIsExamComplete = (exam) => getHasExamData(exam) && getIsExamConfirmed(exam);
export const getIsExamReadyToStart = (exam) => !getHasExamData(exam) && getIsExamQuestionnaireApproved(exam);

export const getExamStatus = (exam) => {
  const isCheckedIn = getIsExamCheckedIn(exam);
  const isConfirmed = getIsExamConfirmed(exam);
  const isReadyToStart = getIsExamReadyToStart(exam);
  const hasData = getHasExamData(exam);

  if(isConfirmed) {
    if(hasData) {
      return EXAM_STATUS.COMPLETE.text;
    } else {
      return EXAM_STATUS.IN_PROGRESS.text;
    }
  } else {
    if(isReadyToStart) {
      return EXAM_STATUS.READY_TO_START.text;
    } else {
      if(isCheckedIn) {
        return EXAM_STATUS.CHECKING_IN.text;
      } else {
        return EXAM_STATUS.SCHEDULED.text;
      }
    }
  }
};

export const getIsQuestionnaireStarted = questionnaire => !!questionnaire;
export const getIsQuestionnaireSubmitted = questionnaire => !!questionnaire?.formResponse;
export const getIsQuestionnaireApproved = questionnaire => !!questionnaire?.isApproved;

export const getQuestionnaireStatus = (questionnaire) => {
  const isQuestionnaireStarted = getIsQuestionnaireStarted(questionnaire);
  const isQuestionnaireSubmitted = getIsQuestionnaireSubmitted(questionnaire);
  const isQuestionnaireApproved = getIsQuestionnaireApproved(questionnaire);

  if(!isQuestionnaireStarted) {
    return QUESTIONNAIRE_STATUS.NOT_STARTED.text;
  }
  if(isQuestionnaireStarted && !isQuestionnaireSubmitted) {
    return QUESTIONNAIRE_STATUS.IN_PROGRESS.text;
  }
  if(isQuestionnaireSubmitted && !isQuestionnaireApproved) {
    return QUESTIONNAIRE_STATUS.SUBMITTED.text;
  }
  if(isQuestionnaireApproved) {
    return QUESTIONNAIRE_STATUS.APPROVED.text;
  }
};

export const getIsReportGenerated = report => !!report?.documentId;
export const getIsReportProofread = report => !!report?.isProofread;
export const getIsReportFileProduced = report => !!report?.file;

export const getReportStatus = (report) => {
  const isReportGenerated = getIsReportGenerated(report);
  const isReportProofread = getIsReportProofread(report);
  const isReportFileProduced = getIsReportFileProduced(report);

  if(!isReportGenerated) {
    return REPORT_STATUS.NOT_GENERATED.text;
  }
  if(isReportGenerated && !isReportProofread) {
    return REPORT_STATUS.AWAITING_PROOFREAD.text;
  }
  if(isReportProofread && !isReportFileProduced) {
    return REPORT_STATUS.AWAITING_FILE_PRODUCTION.text;
  }
  if(isReportFileProduced) {
    return REPORT_STATUS.FILE_PRODUCED.text;
  }
};
