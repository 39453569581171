import Link from "next/link";
import { useUser } from "contexts/user";
import useFetcher from "hooks/fetcher";
import {
  APPOINTMENT_STATUS,
  CATEGORY_TYPES,
} from "lib/consts";
import { getAppointmentStatus } from "lib/status";
import {
  getBoundsForToday,
} from "utils/date";
import appStyles from "styles/app.module.css";
import PipelineDoctor from "./Doctor";
import styles from "./index.module.css";

const Pipeline = () => {
  const { role, user } = useUser();
  const { midnightToday, midnightTomorrow } = getBoundsForToday();

  const { data: appointments } = useFetcher({
    url: "/api/appointments",
    params: {
      search_date_start: midnightToday,
      search_date_end: midnightTomorrow,
    },
  });

  if(!role || !user) {
    return null;
  }

  const { isDoctor, isVendor } = role;

  if(isDoctor) {
    return <PipelineDoctor />;
  }

  const statusMap = {
    [APPOINTMENT_STATUS.NOT_STARTED.text]: 0,
    [APPOINTMENT_STATUS.CHECKED_IN.text]: 0,
    [APPOINTMENT_STATUS.QUESTIONNAIRE_SUBMITTED.text]: 0,
    [APPOINTMENT_STATUS.QUESTIONNAIRE_APPROVED.text]: 0,
  };

  const total = appointments?.length || 0;

  if(appointments?.length) {
    appointments.forEach((item) => {
      const status = getAppointmentStatus(item);
      if(statusMap.hasOwnProperty(status)) {
        statusMap[status]++;
      }
    });
  }

  const staffStages = [{
    label: "Total scheduled",
    link: "/appointments",
    value: total,
  }, {
    label: "Not arrived",
    link: `/appointments?status=${APPOINTMENT_STATUS.NOT_STARTED.key}`,
    value: statusMap[APPOINTMENT_STATUS.NOT_STARTED.text],
  }, {
    label: "Checking in",
    link: `/appointments?status=${APPOINTMENT_STATUS.CHECKED_IN.key}`,
    value: statusMap[APPOINTMENT_STATUS.CHECKED_IN.text],
  }, {
    label: "Waiting for approval",
    link: `/appointments?status=${APPOINTMENT_STATUS.QUESTIONNAIRE_SUBMITTED.key}`,
    value: statusMap[APPOINTMENT_STATUS.QUESTIONNAIRE_SUBMITTED.text],
  }, {
    label: "Waiting for exam",
    link: `/appointments?status=${APPOINTMENT_STATUS.QUESTIONNAIRE_APPROVED.key}`,
    value: statusMap[APPOINTMENT_STATUS.QUESTIONNAIRE_APPROVED.text],
  }];

  const vendorStages = [{
    label: "Scheduled",
    link: "/appointments",
    value: total,
  }, {
    label: "Not shown yet",
    link: `/appointments?status=${APPOINTMENT_STATUS.NOT_STARTED.key}`,
    value: statusMap[APPOINTMENT_STATUS.NOT_STARTED.text],
  }];

  const stages = isVendor ? vendorStages : staffStages;

  return (
    <div className={styles.root}>
      <h2 className={appStyles.heading2}>{`Today's appointments`}</h2>
      <div className={styles.stages}>
        {stages.map((stage, index) => (
          <div
            className={styles.stage}
            key={index}
          >
            <Link href={stage.link}>
              <div className={styles.stageLabel}>{stage.label}</div>
              <div className={styles.stageValue}>{stage.value}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pipeline;
