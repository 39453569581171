import Link from "next/link";
import { TaskDone } from "components/Icons";
import { useUser } from "contexts/user";
import useFetcher from "hooks/fetcher";
import {
  EXAM_STATUS,
  CATEGORY_TYPES,
} from "lib/consts";
import { getIsExamReadyToStart } from "lib/status";
import {
  getBoundsForToday,
} from "utils/date";
import appStyles from "styles/app.module.css";
import styles from "./index.module.css";

const PipelinePendingExams = () => {
  const { role, user } = useUser();
  const { midnightToday, midnightTomorrow } = getBoundsForToday();

  const { data: exams } = useFetcher({
    url: "/api/exams",
    params: {
      search_date_start: midnightToday,
      search_date_end: midnightTomorrow,
    },
  });

  if(!role || !user) {
    return null;
  }

  const doctorMap = {};

  if(exams?.length) {
    exams.forEach((item) => {
      if(getIsExamReadyToStart(item)) {
        if(!doctorMap[item.doctor._id]) {
          doctorMap[item.doctor._id] = {
            doctor: item.doctor,
            count: 0,
          };
        }
        doctorMap[item.doctor._id].count++;
      }
    });
  }

  const doctors = Object.keys(doctorMap);

  const hasExams = !!doctors.length;

  return (
    <div className={styles.root}>
      <h2 className={appStyles.heading2}>{`Ready to be examined`}</h2>
      <div className={styles.stages}>
        {doctors.map((key, index) => (
          <div
            className={styles.stage}
            key={key}
          >
            <Link href={`/exams?doctor=${key}&status=${EXAM_STATUS.READY_TO_START.key}`}>
              <div className={styles.stageLabel}>{`Dr. ${doctorMap[key].doctor.nameLast}`}</div>
              <div className={styles.stageValue}>{doctorMap[key].count}</div>
            </Link>
          </div>
        ))}
      </div>
      {!hasExams && 
        <div className={styles.message}>
          <TaskDone />
          <p className={styles.messageText}>No claimants waiting to be examined.</p>
        </div>
      }
    </div>
  );
};

export default PipelinePendingExams;
